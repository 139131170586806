import { render, staticRenderFns } from "./Associacao.vue?vue&type=template&id=48efb3f4&scoped=true&"
import script from "./Associacao.vue?vue&type=script&lang=js&"
export * from "./Associacao.vue?vue&type=script&lang=js&"
import style0 from "./Associacao.vue?vue&type=style&index=0&id=48efb3f4&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48efb3f4",
  null
  
)

export default component.exports