<template>
  <div v-if="!isFetching" class="md-layout">
    <div v-if="!isFetching2" class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment_ind</md-icon>
          </div>
          <h4 class="title">
            Papeis e Usuários Associados
          </h4>
        </md-card-header>

        <md-card-content>
          <p></p>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <div class="md-toolbar-section-start">
                <md-field>
                  <label for="pages">Por pagina</label>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
                <span class="space-tool-bar"></span>
              </div>
              <div class="md-toolbar-section-start">
                <md-field>
                  <label for="filtro">Filtrar por Papel</label>
                  <md-select
                    v-model="filtro"
                    name="filtro"
                    @md-selected="onChange"
                  >
                    <md-option
                      v-for="item in papelFilter"
                      :key="item.idPapel"
                      :label="item.nomePapel"
                      :value="item.nomePapel"
                    >
                      {{ item.nomePapel }}
                    </md-option>
                  </md-select>
                </md-field>
                <span class="space-tool-bar"></span>
              </div>
              <div class="md-toolbar-section-start">
                <md-field>
                  <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    placeholder="Pesquisar"
                    v-model="searchQuery"
                  >
                  </md-input>
                  <md-icon>search</md-icon>
                </md-field>
              </div>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id Usuario" md-sort-by="idUsuario">{{
                item.idUsuario
              }}</md-table-cell>
              <md-table-cell md-label="Nome Usuario" md-sort-by="nomeusuario">{{
                item.nomeusuario
              }}</md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="email">{{
                item.email
              }}</md-table-cell>
              <md-table-cell
                md-label="Nome Completo"
                md-sort-by="nomeCompleto"
                >{{ item.nomeCompleto }}</md-table-cell
              >
              <md-table-cell md-label="CPF" md-sort-by="cpfUsuario">{{
                item.cpfUsuario
              }}</md-table-cell>

              <md-table-cell
                md-label="Nome Entidade"
                md-sort-by="nomeEntidade"
                >{{ item.nomeEntidade }}</md-table-cell
              >

              <md-table-cell md-label="Nome Papel" md-sort-by="nomePapel">{{
                item.nomePapel
              }}</md-table-cell>

              <!--<md-table-cell md-label="Ações">
                <md-button
                  class="md-just-icon md-simple"
                  :class="checkAssingmentStatus(item)"
                  @click.native="handleAssingment(item)"
                  :disabled="checkAssingmentDisabled(item)"
                >
                  <md-icon>assignment_ind</md-icon>
                </md-button>
              </md-table-cell>-->
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.email"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Mostrando {{ from + 1 }} até {{ to }} de {{ total }} registros
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";

import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";

import { Pagination } from "@/components";
//import papeis from "./papeis";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  props: {
    id: {
      type: String
    }
  },
  components: {
    Pagination
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("papel", {
      papelSelected: "papelSelectedState"
    }),
    user_profile_state: state => state.user_profile,
    reload: function() {
      this.getPapelAll();
      this.getUsuariosAll();
      return this.queriedData;
    },

    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      } else {
        result = [];
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      mensagem: "",
      idPapelSelected: this.id,
      isFetching: true,
      isFetching2: true,
      form: {
        idUsuario: "",
        nomeusuario: "",
        idPapel: ""
      },
      papel: "",
      currentSort: "email",
      currentSortOrder: "desc",
      papelFilter: [],
      filtro: "",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      footerTable: [
        "Id Usuario",
        "Nome Usuario",
        "Email",
        "Nome Completo",
        "CPF",
        "Nome Entidade",
        "Papel Atual"
      ],
      searchQuery: "",
      propsToSearch: [
        "nomeusuario",
        "email",
        "nomeCompleto",
        "cpfUsuario",
        "nomeEntidade"
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  beforeMount() {
    this.getPapel();
    this.getPapelSelected();
    this.getPapelAll().then(() =>
      this.getUsuariosAll().then(() => {
        this.filtro = this.papelSelected.nomePapelSelected;
        this.onChange();
      })
    );
  },
  methods: {
    ...mapActions("user", ["ActionGetUserAssingmentPapelAll"]),
    ...mapActions("papel", [
      "ActionGetPapelAll",
      "ActionGetPapelById",
      "ActionSetPapel"
    ]),

    async getPapelSelected() {
      try {
        let papel = this.papel;
        let id = this.idPapelSelected;
        let response = await this.ActionGetPapelById({
          papel,
          id
        });
        let nomePapelSelected = response.body.nomePapel;
        this.ActionSetPapel({ nomePapelSelected });
        this.isFetching = false;
        return response.body;
      } catch (err) {
        console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
        console.log(err);
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    async getPapelAll() {
      try {
        let papel = this.papel;
        let response = await this.ActionGetPapelAll({ papel });
        let papelFilter = response.body;
        papelFilter.push({
          idPapel: "",
          nomePapel: "",
          descricaoPapel: ""
        });
        papelFilter.sort((a, b) => {
          if (a.nomePapel < b.nomePapel) return -1;
          if (a.nomePapel > b.nomePapel) return 1;
          return 0;
        });
        this.papelFilter = papelFilter;
        this.isFetching2 = false;

        return response.body;
      } catch (err) {
        console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
        console.log(err);
      }
    },
    async getUsuariosAll() {
      try {
        let papel = this.papel;
        let response = await this.ActionGetUserAssingmentPapelAll({ papel });
        this.tableData = response.body;
        this.isFetching = false;
        return response.body;
      } catch (err) {
        console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
        console.log(err);
      }
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    onChange: function() {
      let result = this.tableData;

      if (this.filtro === "") {
        if (this.searchQuery !== "") {
          this.fuseSearch = new Fuse(result, {
            keys: [
              "nomeusuario",
              "email",
              "nomeCompleto",
              "cpfUsuario",
              "nomeEntidade"
            ],
            threshold: 0.2
          });
          result = this.fuseSearch.search(this.searchQuery);
          this.fuseSearch = null;
          this.searchedData = result;
        } else {
          this.searchedData = this.tableData;
        }
        result = this.searchedData;
        return result;
      } else {
        this.fuseSearch = new Fuse(result, {
          keys: ["nomePapel"],
          threshold: -1
        });
        result = this.fuseSearch.search(this.filtro);
        this.fuseSearch = null;
        if (result.length === 0) {
          result = this.searchedData = [
            {
              idPapel: "",
              nomePapel: "",
              descricaoPapel: ""
            }
          ];
          return result;
        } else {
          this.searchedData = result;
          return this.searchedData;
        }
      }
      return result;
    }
  },
  mounted() {
    this.filtro = this.papelSelected.nomePapelSelected;
    // Fuse search initialization.
    /*this.fuseSearch = new Fuse(this.tableData, {
      keys: ["nomePapel", "descricaoPapel"],
      threshold: 0.3
    });*/
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        if (this.filtro !== "") {
          this.fuseSearch = new Fuse(this.searchedData, {
            keys: [
              "nomeusuario",
              "email",
              "nomeCompleto",
              "cpfUsuario",
              "nomeEntidade"
            ],
            threshold: 0
          });
          result = this.fuseSearch.search(this.searchQuery);
          if (result.length === 0) {
            result = this.searchedData;
          }
          this.fuseSearch = null;
        } else {
          this.fuseSearch = new Fuse(result, {
            keys: [
              "nomeusuario",
              "email",
              "nomeCompleto",
              "cpfUsuario",
              "nomeEntidade"
            ],
            threshold: 0.1
          });
          result = this.fuseSearch.search(this.searchQuery);
          this.fuseSearch = null;
          if (result.length === 0) {
            result = [];
          }
        }
      } else {
        result = this.onChange();
      }
      this.searchedData = result;
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
</style>
